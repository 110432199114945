@use 'base';

$card-font-colour: #1F2433;

.project-list {
  display: flex;
}

.project-card {
  background-color: base.$light-shade;
  color: $card-font-colour;
}

.ui.image.project-card-image {
  max-height: 200px;
  object-fit: cover;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
}


