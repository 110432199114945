@use 'components/base';

#root {
  background: base.$dark-shade;
  color: base.$light-shade;
}

.app {
  font-family: base.$font-stack;
  padding-bottom: 10em;
}

