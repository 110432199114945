@use "base";

.landing-section {
  height: 100vh;
  padding-top: 15em;
  margin-bottom: 2.5em;
}

.header {
  text-align: center;
}

#name {
  font-size: 48px;
  text-shadow: 2px 2px 2px base.$primary-colour;
}

.subtext {
  margin-right: 4px;
}

#bottom-section {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0 auto 1em;
  text-align: center;
}

#icon-links {
  display: flex;
  justify-content: center;

  i {
    margin: 0 4px;
  }
}
