$font-stack: 'Raleway', sans-serif;

$light-shade: #EFEDF0;
$light-accent: #8F9460;

$primary-colour: #7E99AF;

$dark-accent: #A0506F;
$dark-shade: #283752;

.ui.label {
  background-color: $dark-accent;
  color: $light-shade;
}

.section {
  margin-bottom: 10em;
  padding-top: 10em;
}

a {
  color: inherit;

  :hover {
    color: $primary-colour;
  }
}

h1, h2, h3, p {
  text-align: center;
}
